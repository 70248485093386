.App {
  padding: 0;
}

.Header {
  border-bottom: 1px solid #ddd;
}

.list-group {
  max-height: 412px;
  overflow-y: auto;
}

.dragBox {
  width: 1000px;
  height: 600px;
  position: relative;
  margin: 0 auto;
  border: 1px solid #ccc;
}

.studentsBox {
  width: 200px;
  height: 600px;
  position: absolute;
  border-right: 1px solid #ccc;
}

.layoutBox {
  width: 800px;
  height: 600px;
  position: absolute;
  left: 200px;
}

.cube {
  display: inline-block;
  --color: #4af;
}

#selecto1 .cube {
  transition: all ease 0.2s;
}

.moveable #selecto1 .cube {
  transition: none;
}

.selecto-area .selected {
  color: #fff;
  background: var(--color);
}

.scroll {
  overflow: auto;
  padding-top: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.infinite-viewer,
.scroll {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
}

.infinite-viewer .viewport {
  padding-top: 10px;
}

.empty.elements {
  border: none;
}

.header-button {
  border: none;
  background-color: inherit;
  padding: 0px 0px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  display: inline-block;
}

.SeatingChartDesk {
  user-select: none;
}

.SeatingChartStudent {
  user-select: none;
}
